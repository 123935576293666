import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./scss/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./login/Login"));
const AboutUs = React.lazy(() => import("./login/AboutUs"));
const PrivacyPolicy = React.lazy(() => import("./login/PrivacyPolicy"));
const HomeFAQ = React.lazy(() => import("./login/HomeFAQ"));
const HomeContactUs = React.lazy(() => import("./login/HomeContactUs"));
const HomeSubscription = React.lazy(() => import("./login/HomeSubscription"));
const AdminLogin = React.lazy(() => import("./login/AdminLogin"));

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/"
              name="Home"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/AboutUsHome"
              name="Home"
              render={(props) => {
                return <AboutUs {...props} />;
              }}
            />
            <Route
              exact
              path="/PrivacyPolicyHome"
              name="Home"
              render={(props) => {
                return <PrivacyPolicy {...props} />;
              }}
            />
            <Route
              exact
              path="/HomeFAQ"
              name="Home"
              render={(props) => {
                return <HomeFAQ {...props} />;
              }}
            />
            <Route
              exact
              path="/HomeContactUs"
              name="Home"
              render={(props) => {
                return <HomeContactUs {...props} />;
              }}
            />
            <Route
              exact
              path="/HomeSubscription"
              name="Home"
              render={(props) => {
                return <HomeSubscription {...props} />;
              }}
            />
            <Route
              exact
              path="/AdminLogin"
              name="Home" 
              render={(props) => {
                return <AdminLogin {...props} />;
              }}
            />
            <Route
              path="/"
              name="Home"
              render={(props) => <TheLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
